<template>
  <v-form>
    <v-select
      v-model="email"
      :items="emails"
      placeholder="Email"
      :error-messages="credentialsError"
    />
    <v-text-field
      v-model="password"
      placeholder="password"
      :type="'password'"
    />
    <v-btn
      color="primary"
      :loading="requesting"
      @click="login"
    >
      Login
    </v-btn>
  </v-form>
</template>

<script>
import { loginUser } from '@/api/AuthRepository'

export default {
  name: 'LoginForm',
  data: () => ({
    password: 'password',
    email: 'admin@email.com',
    emails: [
      'admin@email.com',
      'stock_manager@email.com',
      'operator@email.com',
      'entity_admin@email.com',
    ],
    requesting: false,
    credentialsError: [],
  }),
  computed: {
    user () {
      return this.$root.$data.user
    },
  },
  watch: {
    user (newValue) {
      if (newValue) {
        this.$router.go('/')
      }
    },
  },
  methods: {
    login () {
      this.requesting = true
      loginUser(this.email, this.password)
        .then(() => {
          return this.$store.dispatch('fetchUser')
        })
        .then(() => {
          this.$router.push('/')
        })
        .catch((error) => {
          this.requesting = false
          if (error.response && error.response.status === 401) {
            this.credentialsError = [
              this.$t('credentialsError'),
            ]
          }
        })
    },
  },
  i18n: {
    messages: {
      it: {
        credentialsError: 'Le credenziali di accesso sono errate.',
      },
      en: {
        credentialsError: 'The auth credentials are wrong.',

      },
    },
  },
}
</script>

<i18n>
{
  "en": {
    "accessCode": "Access code!"
  },
  "it": {
    "accessCode": "Hello i18n in SFC!"
  }
}
</i18n>
