<template>
  <v-container class="fill-height justify-center align-center">
    <v-row class="justify-center">
      <v-col

        cols="12"
        md="4"
      >
        <v-img
          src="cristaltec-logo.png"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col

        cols="12"
        md="4"
      >
        <v-card>
          <v-card-text>
            <login-form />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import LoginForm from '@/views/auth/components/LoginForm'

export default {
  name: 'Login',
  components: { LoginForm },
}
</script>
